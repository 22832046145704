import { graphql, Link } from "gatsby"
import React from "react"
import Layout, { Container } from "../components/layout"
import Slanted, {
  HeroHeading,
  NormalHeading,
} from "../components/layout/slanted"
import SEO from "../components/SEO"
import { htmlToText } from "html-to-text"
import truncate from "truncate"

const Manifest = ({ data }) => {
  const {
    allWpProgram: { edges },
    program,
  } = data
  return (
    <Layout>
      <SEO
        title="Valmanifest"
        description={program.pageFields.summary}
        image={program.featuredImage.node.localFile.childImageSharp.fluid}
      ></SEO>

      <Slanted
        imageData={program.featuredImage.node.localFile.childImageSharp.fluid}
      >
        <div>
          <HeroHeading>Valmanifest</HeroHeading>
          <div className="text-white text-shadow-contrast">
            <div
              dangerouslySetInnerHTML={{ __html: program.pageFields.summary }}
            />
          </div>
        </div>
      </Slanted>
      <Slanted innerClass="bg-white">
        {edges.map(({ node: program }, index) => (
          <Link to={program.uri} className="no-underline" key={index}>
            <h2>{program.title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: program.kyrkoProgramFields.kortversion,
              }}
            ></div>
            <div>
              <h4>Vi vill:</h4>
              <ul className="list-disc list-inside">
                {program.kyrkoProgramFields.attSatser.map(({ sats }, index) => (
                  <li key={index}>{sats}</li>
                ))}
              </ul>
            </div>
            <div className="mt-4 text-lg text-right md:text-left">
              <Link to={program.uri}>Läs hela texten här!</Link>
            </div>
          </Link>
        ))}
      </Slanted>
    </Layout>
  )
}

export default Manifest

export const query = graphql`
  query KyrkoprogramsQuery {
    program: wpPage(slug: { eq: "program" }) {
      id
      title
      content
      pageFields {
        summary
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allWpProgram(sort: { fields: kyrkoProgramFields___order }) {
      edges {
        node {
          title
          uri
          kyrkoProgramFields {
            attSatser {
              sats
            }
            kortversion
          }
        }
      }
    }
  }
`
